import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import useRequests from '../../hooks/useRequests';

const WhatsAppOnboardingPage = () => {
  const requests = useRequests();
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
        return;
      }

      try {
        const data = JSON.parse(event.data);

        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;

            console.log('✅ Embedded Signup completado');
            console.log('WABA ID:', waba_id);
            console.log('Phone number ID:', phone_number_id);

            // Aquí puedes guardar estos datos en tu backend
            // para luego hacer llamadas con tu System User Token
          }

          if (data.event === 'CANCEL') {
            console.warn('⛔️ Usuario canceló el Embedded Signup');
          }

          if (data.event === 'ERROR') {
            console.error('❌ Error en Embedded Signup:', data.data.error_message);
          }
        }

        document.getElementById('session-info-response').textContent = JSON.stringify(data, null, 2);
      } catch (e) {
        console.log('⚠️ Non-JSON response:', event.data);
      }
    });

    // Cargar SDK solo una vez
    if (!document.getElementById('facebook-jssdk')) {
      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      document.body.appendChild(script);
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '2219470115115225',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v17.0',
      });
    };
  }, []);

  // async function getAccessToken(code) {
  //   try {
  //     const response = await fetch(`https://us-central1-kesildigital-bot.cloudfunctions.net/whatsapp-embbeded-signup-exchange-code?code=${code}`);
  //     const data = await response.json();

  //     if (response.status === 200) {
  //       console.log('Success', data);
  //     } else {
  //       console.error('Error', data);
  //     }

  //     return data;
  //   } catch (error) {
  //     console.error('Error', error);
  //   }
  // }

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log({ code });
      // getAccessToken(code).then((response) => {
      //   console.log('Access token obtained successfully', response);
      // });
      // The returned code must be transmitted to your backend first and then
      // perform a server-to-server call from there to our servers for an access token.
    }
    document.getElementById('sdk-response').textContent = JSON.stringify(response, null, 2);
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: '685423990817650',
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      },
    });
  };

  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="wpo-contact-title">
            {/* <Button variant="contained" color="primary" onClick={() => getAccessToken('test_code')}>
              TEST WEBHOOK
            </Button> */}
            <h2> Autorizar whatsapp</h2>

            <Button onClick={launchWhatsAppSignup}>LOGIN WITH FACEBOOK</Button>
            <p>Session info response:</p>
            <pre id="session-info-response"></pre>
            <p>SDK response:</p>
            <pre id="sdk-response"></pre>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map"></div>
      </section>
    </section>
  );
};

export default WhatsAppOnboardingPage;
