import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const PrivacyPolicyPageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/privacy-policy' });
  const metaTagsProps = {
    title: 'Política de Tratamiento de Datos | Kesil Digital',
    description:
      'Conoce la política de tratamiento de datos de Kesil Digital. Garantizamos un manejo justo, transparente y seguro de tus datos personales.',
    url: '/es/politica-de-privacidad',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 alwaysFixed />
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <h2>Política de privacidad</h2>
            <br />
            <br />
            <h5 style={{ marginTop: '10px' }}>1. Información que Recopilamos</h5>
            <p>
              Recopilamos los siguientes datos personales de nuestros clientes y, en algunos casos, de los usuarios finales de sus negocios cuando sea
              necesario para la prestación del servicio:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Nombre</li>
              <li>Número de teléfono</li>
              <li>Correo electrónico</li>
              <li>
                Datos procesados a través de nuestras automatizaciones, incluyendo mensajes de WhatsApp y otros datos relevantes según la
                configuración del servicio contratado
              </li>
              <li>Información técnica y de uso en el desarrollo de software a la medida</li>
            </ul>

            <p>
              Estos datos se recopilan únicamente para la prestación de nuestros servicios de automatización y desarrollo de software y no serán
              utilizados para otros fines sin el consentimiento del usuario.
            </p>

            <h5 style={{ marginTop: '10px' }}>2. Uso de la Información</h5>
            <p>Utilizamos los datos recopilados exclusivamente para:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Implementar y operar nuestros servicios de automatización en WhatsApp, n8n, Airtable, Make.com y otras plataformas.</li>
              <li>Desarrollar software a la medida adaptado a las necesidades de nuestros clientes.</li>
              <li>Permitir a nuestros clientes gestionar la comunicación y automatizar sus flujos de trabajo.</li>
              <li>Mejorar y optimizar nuestros servicios.</li>
            </ul>
            <p>No compartimos, vendemos ni transferimos datos personales a terceros, excepto en los siguientes casos:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li> Para cumplir con obligaciones legales o regulatorias.</li>
              <li>En caso de solicitud de una autoridad competente dentro de los límites de la ley.</li>
              <li>
                Si utilizamos herramientas de análisis de datos o servicios adicionales que requieran el procesamiento de datos personales,
                informaremos a los usuarios de manera explícita.
              </li>
            </ul>
            <h5 style={{ marginTop: '10px' }}>3. Integraciones con Terceros</h5>
            <p>
              Kesil Digital utiliza servicios de terceros, como WhatsApp, Airtable, n8n, Make.com y bases de datos seguras, para el almacenamiento y
              gestión de la información. Nos aseguramos de que estos servicios cumplan con estándares adecuados de seguridad y privacidad.
            </p>
            <p>
              Los usuarios deben revisar también las políticas de privacidad de estas plataformas, ya que sus datos pueden estar sujetos a
              regulaciones externas establecidas por dichas empresas.
            </p>

            <h5 style={{ marginTop: '10px' }}>4. Seguridad de la Información</h5>
            <p>
              Tomamos medidas razonables para proteger los datos personales contra el acceso no autorizado, alteración, divulgación o destrucción.
              Entre estas medidas se incluyen:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Almacenamiento en bases de datos protegidas.</li>
              <li>Control de acceso restringido.</li>
              <li>Protocolos de seguridad para la transmisión de datos.</li>
            </ul>
            <p>
              Uso de cifrado y otras tecnologías de seguridad cuando sea necesario. A pesar de nuestros esfuerzos, ningún sistema es completamente
              seguro. En caso de una posible vulneración de datos, notificaremos a los usuarios afectados según lo exija la ley aplicable.
            </p>

            <h5 style={{ marginTop: '10px' }}>5. Retención y Eliminación de Datos</h5>
            <p>
              Los datos se almacenan mientras el usuario mantenga una relación activa con nosotros. Si un usuario desea eliminar su información, puede
              solicitarlo enviando un correo electrónico a contacto@kesildigital.com. Procederemos con la eliminación dentro de un plazo razonable.
            </p>

            <p>
              Nos comprometemos a eliminar la información dentro de un plazo de 30 días después de la solicitud. Sin embargo, algunos datos podrán
              conservarse por razones legales, de auditoría o de cumplimiento de regulaciones.
            </p>

            <h5 style={{ marginTop: '10px' }}>6. Cumplimiento con Regulaciones</h5>
            <p>
              Actualmente, no seguimos una regulación específica, pero estamos comprometidos con la protección de datos y adoptamos medidas alineadas
              con las mejores prácticas del sector, incluyendo:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Transparencia en el manejo de datos personales.</li>
              <li>Obtención de consentimiento cuando sea requerido.</li>
              <li> Derecho de los usuarios a solicitar la eliminación de sus datos.</li>
              <li> Evaluaremos y ajustaremos nuestra política según sea necesario para cumplir con futuras regulaciones aplicables.</li>
            </ul>

            <h5 style={{ marginTop: '10px' }}>7. Cumplimiento con WhatsApp y otras Plataformas</h5>
            <p>Nuestro servicio se conecta directamente con la API oficial de WhatsApp y cumple con todas sus políticas, incluyendo:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Uso exclusivo para mensajes transaccionales y permitidos por WhatsApp.</li>
              <li>Respeto a la privacidad de los usuarios.</li>
              <li>Implementación de buenas prácticas para evitar spam y abusos.</li>
            </ul>
            <p>
              Asimismo, nos aseguramos de que nuestras automatizaciones en n8n, Airtable, Make.com y otras plataformas, así como el desarrollo de
              software a la medida, respeten las mejores prácticas de seguridad y cumplimiento de datos.
            </p>

            <h5 style={{ marginTop: '10px' }}>8. Cambios en la Política de Privacidad</h5>
            <p>
              Nos reservamos el derecho de modificar esta política en cualquier momento. En caso de cambios significativos, notificaremos a nuestros
              usuarios a través de nuestro sitio web www.kesildigital.com y/o por correo electrónico cuando corresponda. Los usuarios pueden consultar
              la versión más reciente de esta política en nuestro sitio web en cualquier momento.
            </p>

            <h5 style={{ marginTop: '10px' }}>9. Contacto</h5>
            <p>Para cualquier consulta sobre esta política, puedes escribirnos a contacto@kesildigital.com.</p>
          </div>
        </div>
        <section className="wpo-contact-map-section">
          <div className="wpo-contact-map"></div>
        </section>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PrivacyPolicyPageES;
