import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import HomepageES from '../HomePage/HomePage.es';
import HomepageEN from '../HomePage/HomePage.en';
import AboutPageES from '../AboutPage/AboutPage.es';
import ServicePageES from '../ServicePage/ServicesPage.es';
import ContactPageES from '../ContactPage/ContactPage.es';
import ThankyouPage from '../ThankyouPage';
import DataPolicyPageES from '../DataPolicyPage/DataPolicyPage.es';
import BlogPageES from '../BlogPage/BlogPage.es';
import blogs from '../../api/blogs';
import BlogDetails from '../BlogDetails';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage';
import AboutPageEN from '../AboutPage/AboutPage.en';
import ContactPageEN from '../ContactPage/ContactPage.en';
import ServicePageEN from '../ServicePage/ServicesPage.en';
import DataPolicyPageEN from '../DataPolicyPage/DataPolicyPage.en';
import BlogPageEN from '../BlogPage/BlogPage.en';
import PrivacyPolicyPageES from '../PrivacyPolicy/PrivacyPolicyPage.es';
import PrivacyPolicyPageEN from '../PrivacyPolicy/PrivacyPolicyPage.en';
import WhatsAppOnboarding from '../WhatsAppOnboarding';

const AllRoute = () => {
  const { i18n } = useTranslation();
  const rootRedirect = i18n.language === 'en' ? '/en' : '/es';
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* es-routes */}
          <Route path="/" element={<Navigate to={rootRedirect} replace />} />
          <Route exact path="/es" element={<HomepageES />} />
          <Route path="/es/contacto" element={<ContactPageES />} />
          <Route path="/es/nosotros" element={<AboutPageES />} />
          <Route path="/es/blogs" element={<BlogPageES />} />
          <Route path="/es/servicios" element={<ServicePageES />} />
          <Route path="/es/politica-de-tratamiento-de-datos" element={<DataPolicyPageES />} />
          <Route path="/es/politica-de-privacidad" element={<PrivacyPolicyPageES />} />
          <Route path="/es/gracias" element={<ThankyouPage />} />
          <Route path="/es/whatsapp-onboarding" element={<WhatsAppOnboarding />} />

          {/* en-routes */}
          <Route exact path="/en" element={<HomepageEN />} />
          <Route path="/en/contact" element={<ContactPageEN />} />
          <Route path="/en/about-us" element={<AboutPageEN />} />
          <Route path="/en/blogs" element={<BlogPageEN />} />
          <Route path="/en/services" element={<ServicePageEN />} />
          <Route path="/en/data-processing-policy" element={<DataPolicyPageEN />} />
          <Route path="/en/privacy-policy" element={<PrivacyPolicyPageEN />} />
          <Route path="/en/thank-you" element={<ThankyouPage />} />

          {/* blogs */}
          {blogs.map((blog) => {
            return (
              <>
                <Route path={`/en/blog/${blog.url.en}`} element={<BlogDetails Component={blog.Component.EN} blog={blog} />} />
                <Route path={`/es/blog/${blog.url.es}`} element={<BlogDetails Component={blog.Component.ES} blog={blog} />} />
              </>
            );
          })}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
