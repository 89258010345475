import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const PrivacyPolicyPageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es/politica-de-privacidad' });
  const metaTagsProps = {
    title: 'Data Processing Policy | Kesil Digital',
    description: "Learn about Kesil Digital's data processing policy. We ensure fair, transparent, and secure handling of your personal data.",
    url: '/en/privacy-policy',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 alwaysFixed />
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <h2>Privacy Policy</h2>
            <br />
            <br />
            <h5 style={{ marginTop: '10px' }}>1. Information We Collect</h5>
            <p>
              We collect the following personal data from our clients and, in some cases, from the end users of their businesses when necessary for
              service delivery:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Name</li>
              <li>Phone number</li>
              <li>Email address</li>
              <li>
                Data processed through our automations, including WhatsApp messages and other relevant data according to the service configuration.
              </li>
              <li>Technical and usage information in custom software development</li>
            </ul>

            <p>
              This data is collected solely for the provision of our automation and software development services and will not be used for other
              purposes without user consent.
            </p>

            <h5 style={{ marginTop: '10px' }}>2. Use of Information</h5>
            <p>We use the collected data exclusively to:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Implement and operate our automation services on WhatsApp, n8n, Airtable, Make.com, and other platforms.</li>
              <li>Develop custom software tailored to our clients' needs.</li>
              <li>Allow our clients to manage communication and automate their workflows.</li>
              <li>Improve and optimize our services.</li>
            </ul>
            <p>We do not share, sell, or transfer personal data to third parties, except in the following cases:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>To comply with legal or regulatory obligations.</li>
              <li>In response to a request from a competent authority within the limits of the law.</li>
              <li>If we use data analysis tools or additional services that require personal data processing, we will explicitly inform users.</li>
            </ul>

            <h5 style={{ marginTop: '10px' }}>3. Third-Party Integrations</h5>
            <p>
              Kesil Digital uses third-party services such as WhatsApp, Airtable, n8n, Make.com, and secure databases for data storage and management.
              We ensure that these services meet appropriate security and privacy standards.
            </p>
            <p>
              Users should also review the privacy policies of these platforms, as their data may be subject to external regulations established by
              these companies.
            </p>

            <h5 style={{ marginTop: '10px' }}>4. Information Security</h5>
            <p>
              We take reasonable measures to protect personal data against unauthorized access, alteration, disclosure, or destruction. These measures
              include:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Storage in secure databases.</li>
              <li>Restricted access control.</li>
              <li>Security protocols for data transmission.</li>
            </ul>
            <p>
              We use encryption and other security technologies when necessary. Despite our efforts, no system is completely secure. In case of a
              potential data breach, we will notify affected users as required by applicable law.
            </p>

            <h5 style={{ marginTop: '10px' }}>5. Data Retention and Deletion</h5>
            <p>
              Data is stored as long as the user maintains an active relationship with us. If a user wishes to delete their information, they can
              request it by sending an email to contacto@kesildigital.com. We will proceed with deletion within a reasonable timeframe.
            </p>

            <p>
              We commit to deleting the information within 30 days after the request. However, some data may be retained for legal, audit, or
              regulatory compliance reasons.
            </p>

            <h5 style={{ marginTop: '10px' }}>6. Compliance with Regulations</h5>
            <p>
              Currently, we do not follow a specific regulation, but we are committed to data protection and adopt measures aligned with industry best
              practices, including:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Transparency in the handling of personal data.</li>
              <li>Obtaining consent when required.</li>
              <li>Users' right to request the deletion of their data.</li>
              <li>We will evaluate and adjust our policy as necessary to comply with future applicable regulations.</li>
            </ul>

            <h5 style={{ marginTop: '10px' }}>7. Compliance with WhatsApp and Other Platforms</h5>
            <p>Our service connects directly with WhatsApp's official API and complies with all its policies, including:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '40px', marginBottom: '12px' }}>
              <li>Exclusive use for transactional messages and those permitted by WhatsApp.</li>
              <li>Respect for users' privacy.</li>
              <li>Implementation of best practices to avoid spam and abuse.</li>
            </ul>
            <p>
              Likewise, we ensure that our automations on n8n, Airtable, Make.com, and other platforms, as well as custom software development, adhere
              to best security and data compliance practices.
            </p>

            <h5 style={{ marginTop: '10px' }}>8. Changes to the Privacy Policy</h5>
            <p>
              We reserve the right to modify this policy at any time. In the event of significant changes, we will notify our users through our
              website www.kesildigital.com and/or by email when applicable. Users can consult the latest version of this policy on our website at any
              time.
            </p>

            <h5 style={{ marginTop: '10px' }}>9. Contact</h5>
            <p>For any inquiries regarding this policy, you can write to us at contacto@kesildigital.com.</p>
          </div>
        </div>
        <section className="wpo-contact-map-section">
          <div className="wpo-contact-map"></div>
        </section>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PrivacyPolicyPageEN;
