import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import contactBg from '../../images/contact-header.webp';
import WhatsAppOnboardingPage from '../../components/WhatsAppOnboarding';

const WhatsAppOnboarding = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Gracias por contactarnos'} bgImage={contactBg} noTitle />
      <WhatsAppOnboardingPage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default WhatsAppOnboarding;
